import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuoteComponent } from './quote/quote.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
  {
    path: 'ccd2e3eaa5c991ac880991328c8f1463/:hash/:passHash',
    loadChildren: './userTemplates/userTemplate0001.module#UserTemplate0001Module',
  }, // individuelles template
  {
    path: '123/:hash/:passHash',
    loadChildren: './userTemplates/userTemplate0001.module#UserTemplate0001Module',
  }, // individuelles template
  {
    path: 'base_template/:hash/:passHash',
    loadChildren: './userTemplates/userTemplate0002.module#UserTemplate0002Module',
  }, // individuelles template
  { path: ':template/:hash/:passHash', component: QuoteComponent },
  { path: '**', component: ErrorComponent} // PageNotFoundComponent, navigate: {skipLocationChange: true}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
