import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { QuoteComponent } from './quote/quote.component';

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Preisanzeige
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
registerLocaleData(localeDE);


// Default Template
import { DefaultTemplateComponent } from './defaultTemplate/defaultTemplate.component';

import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
// import { ErrorComponent } from './error/error.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    QuoteComponent,
    DefaultTemplateComponent
  ],
  entryComponents: [],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, NgbModule, CommonModule, SharedModule, BrowserAnimationsModule],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de' // 'de-DE' for Germany, 'fr-FR' for France ...
    }
  ],
  bootstrap: [AppComponent]
  // exports: [ErrorComponent]
})
export class AppModule {}
