import { Component } from '@angular/core';
import { LoadQuote } from './loadQuote';

// console.log('Loading default quote');
@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./background.css']
})

export class QuoteComponent extends LoadQuote { }
