import { Component, Input } from '@angular/core';

// console.log('DEBUG: Template Default');
@Component({
  selector: 'app-quote-default',
  templateUrl: './defaultTemplate.component.html',
  styleUrls: ['../quote/quote.component.scss']
})
export class DefaultTemplateComponent {
  @Input() title: string;
  @Input() quote: any;
  @Input() response: any;
  @Input() itemsSumEx: number;
  @Input() vat: number;
  @Input() itemsSumInc: number;
  oldOffer = false;

  round2Fixed(value: any) {
    value = +value;

    if (isNaN(value)) {
      return NaN;
    }

    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + 2 : 2)));

    // Shift back
    value = value.toString().split('e');

    value = (+(value[0] + 'e' + (value[1] ? +value[1] - 2 : -2))).toFixed(2);

    // Komma
    value = value.replace(/\./, ',');

    return value;
  }

  getData() {
    const mailBody: string[] = [];
    mailBody.push(
      'Sehr geehrte Damen und Herren',
      '',
      'gerne nehme ich Ihr Angebot [' +
        this.quote.note_id +
        '] ' +
        this.quote.note_name +
        ' für ' +
        this.round2Fixed(this.itemsSumInc) +
        '€ an',
      ''
    );

    // for (const item of this.quote.item) {
    //   mailBody.push(
    //     item.item_qty +
    //     ' mal ' /* + item.desc_short */ +
    //       '[' +
    //       item.map_id +
    //       '] für ' +
    //       this.round2Fixed(item.vk_netto * item.item_qty) +
    //       '€'
    //   );
    // }

    // mailBody.push('', 'Gesamtpreis: ' + this.round2Fixed(this.itemsSumInc) + ' €');

    return mailBody.join('%0D%0A');
  }

  sendMail(data?: string) {
    let localData: string;
    if (this.response.offer_time_end && (new Date(this.response.offer_time_end).getTime() + (24 * 60 * 60 * 1000) < new Date().getTime())) {
      console.log('old');
      console.log(this.response);
      this.oldOffer = true;
      return;
    }
    console.log('new');
    console.log(this.response);
    if (data) {
      localData = data;
    } else {
      localData = this.getData();
    }
    window.location.href =
      'mailto:' + (this.quote.contact.email
      ? this.quote.contact.email
      : this.quote.address.seller.email) + '?body=' + localData + '';
  }
}
