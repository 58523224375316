import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorComponent } from './error/error.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';BrowserAnimationsModule

@NgModule({
 imports:      [ CommonModule ],
 declarations: [ ErrorComponent ],
 exports:      [ ErrorComponent ]
})
export class SharedModule { }
