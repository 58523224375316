import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Quote, QuoteResponse, Item } from './quote';
import { HTMLcss } from './httpCssChild';
import * as CryptoJS from 'crypto-js';
// console.log('Loading base quote');

import { OnInit, Inject } from '@angular/core';

export abstract class LoadQuote implements OnInit {
  error = {
    msg: '',
    status: 404
  };
  title = 'Angebot';
  cssLoaded = false;
  quote: Quote;
  response: QuoteResponse;
  encrypted: string;
  oldOffer = false;

  // Item Preis summe
  itemsSumInc: number;
  itemsSumEx: number;
  vat: number;

  quoteUrl = '/api/quote/';
  cssURL = '/api/css/';
  encryptSecretKey: string; // 'cop_software';

  calcSum() {
    let sum = 0;
    let items: Array<Item>;
    items = this.quote.item;
    items.forEach(item => {
      sum += item.item_qty * item.vk_netto;
    });

    this.itemsSumEx = sum;
    this.vat = this.itemsSumEx * 0.19;
    this.itemsSumInc = this.itemsSumEx + this.vat;
  }

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(ActivatedRoute) private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.encryptSecretKey = this.route.snapshot.paramMap.get('passHash');
    this.getQuote(this.route.snapshot.paramMap.get('hash'));
    if (this.route.snapshot.paramMap.get('template')) {
      this.loadCSS(this.route.snapshot.paramMap.get('template'));
    }
  }

  loadCSS(userId: string) {
    this.changeTheme(userId);
  }

  getQuote(fileName: string) {
    // get the data of the quote
    this.http.get(this.quoteUrl + fileName).subscribe(
      response => {
        this.response = response as QuoteResponse;
        this.quote = this.decryptData(this.response.data);
        if (!this.quote) {
          return;
        }
        this.calcSum();
        if (!this.route.snapshot.paramMap.get('template')) {
          this.loadCSS(this.response.user);
        }
      },
      err => {
        if (err.status !== 404) {
          console.error('connection Error', err);
          const STATUS = err.status || 500;
          const MSG = err.error || 'connection Error';
          this.error.msg = MSG;
          this.error.status = STATUS;
        } else {
          this.error.msg = 'Das Angebot konnte nicht gefunden werden';
        }
      }
    );
  }

  changeTheme(themeName: string) {
    (document.getElementById('themeAsset') as HTMLcss).onload = () => {
      this.cssLoaded = true;
    };
    (document.getElementById(
      'themeAsset'
    ) as HTMLcss).href = `${this.cssURL}${themeName}.css`;
  }

  decryptData(data: string) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.error(e);
      this.error.msg = 'Das Angebot konnten nicht geladen werden';
      this.error.status = 403;
    }
  }

  sendMail(data?: string) {
    let localData: string;
    if (this.response.offer_time_end && (new Date(this.response.offer_time_end).getTime() + (24 * 60 * 60 * 1000) < new Date().getTime())) {
      this.oldOffer = true;
      return;
    }
    if (data) {
      localData = data;
    } else {
      localData = '';
    }
    window.location.href =
      'mailto:' +
      (this.quote.contact.email
        ? this.quote.contact.email
        : this.quote.address.seller.email) +
      '?' +
      localData +
      '';
  }
}
