import { Component, Input, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group
} from '@angular/animations';
// console.log('DEBUG: not found component');
@Component({
  selector: 'app-quote-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ left: 0, opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ left: -25, opacity: 0 }), animate(500)]),
    ])
  ]
})
export class ErrorComponent implements OnInit {
  @Input() title: string;
  @Input() error: any;
  @Input() quote: any;

  ngOnInit() {
    this.setDefaultErrorObject();
  }

  setDefaultErrorObject() {
    if (!this.error) {
      this.error = {};
      this.error.status = 404;
      this.error.msg = 'Das gesuchte Angebot konnte nicht gefunden werden.';
    }
  }
}
